<template>
    <div class="meeting_item_container">
        <component ref="coursePage"  v-if="components" :pageItem="pageItem" v-bind:is="components" :meetId="meetId" :meetInfo="meetInfo"></component>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import DocterPage from '@/components/page/meeting/template/docterPage'
export default {
    name:"meetingItem",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        meetInfo:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        index:{
            type:Number
        },
        coursewareId:Number,
        meetId:Number
    },
    data(){
        return {
            isEdit:false,
            templates:{
                basetemplate:"baseTemplate",
                commontemplate:"commonTemplate",
                systemcommontemplate:"systemCommonTemplate",
                patientsharetemplate:"patientShareTemplate",
                commonpatientsharetemplate:"commonPatientShareTemplate",
                docterpage:"docterPage",
                titletemplate:"titleTemplate",
            }
        }
    },
    components:{
        DocterPage
    },
    computed:{
        components(){
            let path = ''
            if(this.pageItem.template_key == "") {
                path =  `show/defaultTemplate`
            }
            else{
                let folder = this.isEdit ? 'template' : 'show'
                let key = this.pageItem.template_key.toLowerCase()
                path = `${folder}/${this.templates[key]}`
            }
            console.log(`@/components/page/meeting/${path}`)
            return defineAsyncComponent( ()=>import(`@/components/page/meeting/${path}`))
        }
    },
    mounted(){
        this.$emitter.on(`edit${this.index}`,(status)=>{
            this.isEdit = status;
            console.log(this.isEdit)
        })
        this.$emitter.on(`restore${this.index}`,(status)=>{
            this.$nextTick(()=>{
                this.$refs.coursePage && this.$refs.coursePage.restore()
            })
        })
        this.$emitter.on(`clear${this.index}`,(status)=>{
            this.$nextTick(()=>{
                this.$refs.coursePage && this.$refs.coursePage.clearInfo()
            })
        })
        this.$emitter.on(`save${this.index}`,this.doCommit)
    },
    methods:{
        formatData(data){
            let content = []
            this.pageItem.content.forEach((val,index)=>{
                content[index] = {
                    ...val,
                    ...data[index]
                }
            })
            let result = {
                ...this.pageItem,
                content:content
            }
            console.log(result)
            return result
        },
        doCommit(successCallback,errorCallback){
            this.$nextTick(()=>{
                try{
                    this.$refs.coursePage.getData((data)=>{ 
                        console.log(1111111)
                        // 不需要校验
                        if(data.code == 301){  successCallback();return; }
                        // 校验数据出错
                        if(data.code == 400){  errorCallback();return; }
                        let requestData = {
                            courseware_id:this.coursewareId,
                            meet_id:this.meetId,
                            content_page:this.index+1,
                            content:data.data
                        }
                        let loading = this.$tips.customLoading({text:"保存中，请稍后"})
                        this.$axios.patch(this.$urls.meet.pageContent,requestData).then(res=>{
                            console.log(res)
                            this.isEdit = false;
                            loading.close()
                            successCallback(this.formatData(res.data))
                        }).catch(()=>{
                            loading.close()
                            errorCallback()
                        })
                    })
                }catch(err){
                    this.isEdit = false;
                }

            })
        }
    }
}
</script>
<style>
.meeting_item_container{
    height:100%;
    width:100%;
    background:#fff;
}

.meeting_item_container textarea{
    font-size:4vw;
    border:none;
}
</style>